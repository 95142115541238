<template>
  <div class="state-style">
    <div class="state-text">
      <van-icon name="checked" color="#07C160" size="0.8rem" />
      支付成功
    </div>
    <div>是否需要预约挂号发热门诊</div>
    <van-button class="cancle-btn" to="/nucleicPayment">不需要</van-button>
    <van-button class="confirm-btn" @click="gotoAppoint">预约挂号</van-button>
  </div>
</template>

<script>
export default {
  name: "paySuccess",
  methods: {
    gotoAppoint(){
      this.$router.push({
        path: "/doctors",
        query: {
          LabelType: "发热门诊",
          deptCode: "401001",
          type: 0,
          id: this.$route.query.id
        }
      })
    }
  }
};
</script>

<style scoped>
.state-style {
  height: 8rem;
  font-size: 0.4rem;
}
.state-text {
  height: 5rem;
  line-height: 5rem;
  color: #07c160;
  font-size: 0.4rem;
}
.confirm-btn {
  width: 40%;
  border-radius: 10px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.4rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.cancle-btn {
  width: 40%;
  border-radius: 10px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.4rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
</style>